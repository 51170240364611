.c-sidebar {
    background: #0F2027;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
}
.loginbg{
    background-image: linear-gradient(45deg, #93a5cf 0%, #e4efe9 100%);
}


 .btn-login {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  color: #FFFFFF!important;
  font-family: Brush Script MT;
  font-size: 40px;
  font-weight: 100;
  padding: 40px;
  background-color: #E52E71!important;
  text-shadow: 1px 1px 20px #000000!important;
  border: solid #E52E71 1px!important;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  text-align: center;
}

.btn-login:hover {
  background: #E52E71!important;
  border: solid #E52E71 0!important;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  text-decoration: none;
}
 .link{
   text-decoration: none!important;
 }
